import {
  Box,
  CardMedia,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { headingsAndBullets } from "../../data";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const TypographyWithBullets = ({ heading, bullets }) => (
  <Box>
    <Typography
      variant="h2"
      sx={{
        marginTop: { xl: "-13px" },
        fontSize: { lg: "24px", xl: "35px", xs: "24px" },
      }}
    >
      {heading}
    </Typography>
    <Typography border="2px solid transparent">
      <List>
        {bullets.map((bullet, index) => (
          <ListItem
            key={index}
            sx={{
              color: "white",
              fontSize: { lg: "18px", xl: "19px", xs: "19px" },
              fontFamily: "Gilroy-Regular",
              fontWeight: "400",
              lineHeight: { lg: "17px", xl: "27px" },
              marginLeft: { xs: "-17px" },
              // width:{xs:"380px"}
            }}
          >
            <span style={{ marginRight: "07px" }}>•</span>
            {bullet}
          </ListItem>
        ))}
      </List>
    </Typography>
  </Box>
);

const IndustriesCardDetail = ({
  carouselRefCard,
  options
}) => {
  return (
    <>
      <Grid>
        <OwlCarousel {...options} ref={carouselRefCard}>
          {headingsAndBullets.map((item, i) => (
            <CardMedia
              className="industry-card"
              key={i}
              style={{
                width: "100%",
                maxWidth: "100%",
                border: "2px solid transparent",
              }}
            >
              <Grid className="industry-card-details">
                <TypographyWithBullets
                  heading={item.heading}
                  bullets={item.bullets}
                />
              </Grid>
            </CardMedia>
          ))}
        </OwlCarousel>
      </Grid>
    </>
  );
};

export default IndustriesCardDetail;
