import MenuIcon from "@mui/icons-material/Menu";
import { Box, Hidden, IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import "./HamburgerMenu.scss";
import { Link } from "react-scroll";
import Connect from "../../components/buttons/Connect";
import Symbol from "../../assets/Symbol.png";

function HamburgerMenu({ openDrawer }) {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Hidden mdUp>
        <Box
          sx={{
            width: "100%",
            zIndex: "120",
            position: "fixed",
            display: "flex",
            justifyContent: "space-between",
            padding: "4px 16px",
          }}
        >
          <IconButton>
            <img src={Symbol} alt="Techfy logo" />
          </IconButton>
          <IconButton
            onClick={handleDrawerOpen}
            sx={{
              color: "white",
            }}
          >
            <MenuIcon
              sx={{
                fontSize: "2.3rem",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 1)",
                backdropFilter: "blur(5px)",
              }}
            />
          </IconButton>
        </Box>
      </Hidden>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "black",
          },
        }}
      >
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            onClick={handleDrawerClose}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CloseIcon sx={{ width: "4rem", height: "3rem", color: "white" }} />
          </IconButton>

          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {["HOME", "WORK", "SERVICES", "INDUSTRIES"].map((item, index) => (
              <ListItem key={index} style={{ display: "flex", justifyContent: "center" }}>
                <Link to={item.toLowerCase()}>
                  <ListItemText
                    sx={{
                      cursor: "pointer",
                      color: "white",
                      marginBottom: 8,
                    }}
                  >
                    <span className="navlinkMob" onClick={handleDrawerClose}>
                      {item}{" "}
                    </span>
                  </ListItemText>
                </Link>
              </ListItem>
            ))}
          </List>

          <IconButton sx={{ display: "flex", justifyContent: "center" }}>
            <Link
              to="contact"
              spy={true}
              smooth={false}
              offset={0}
              duration={500}
              onClick={handleDrawerClose}
            >
              <Connect />
            </Link>
          </IconButton>
        </Box>
      </Drawer>
    </>
  );
}

export default HamburgerMenu;
