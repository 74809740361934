import React, { useRef, useState } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";
import { Link, Element } from "react-scroll";
import IndustriesCard from "../../components/industriesCard/industriesCard";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";
import "./industries.scss";

import IndustriesCardDetail from "../../components/industriesCard/industriesCardDetail";
import nextButton from "../../assets/nextbutton.png";
import PREV from "../../assets/PREV.png";
import Connect from "../../components/buttons/Connect";
import Learn from "../../components/buttons/Learn";

const Industries = () => {
  const initialColor = "#362408";
  const backgroundColor = useScrollBackgroundColor(initialColor);
  const carouselRef = useRef(null);
  const carouselRefCard = useRef(null);
  const [currentCard, setCurrentCard] = useState(0);

  const handleNext = () => {
    carouselRefCard.current.next();
    if (carouselRef) {
      carouselRef?.current?.next();
    }
  };

  const handlePrev = () => {
    // carouselRefCard.current.prev();
    if (carouselRef) {
      carouselRef?.current?.prev();
    }
  };


  const options = {
    items: 1,
    dots: false,
    startPosition: currentCard,
    mouseDrag: false,
    onChanged: (event) => setCurrentCard(event.item.index),
  };

  return (
    <Element name="industries">
      <Box
        className="industries"
        style={{ backgroundColor, transition: "background-color 0.5s" }}
        sx={{
          display: { xs: "block", md: "flex" },
          alignItems: { md: "center" },
          justifyContent: { md: "center" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: { md: "flex-end", xl: "center" },
            paddingLeft: { lg: "10%", xl: "24px" },
            paddingRight: { lg: "10%", xl: "24px" },
            minHeight: "90vh",
            marginBottom: { xs: "400px", md: "0px" },
            paddingTop: "50px",
          }}
        >
          <Grid container spacing={5}>
            <Grid item xs={12} md={12} lg={6}>
              <IndustriesCard
                carouselRef={carouselRef}
                options={options}
              />
            </Grid>

            <Grid
              item
              md={12}
              lg={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <IndustriesCardDetail
                carouselRefCard={carouselRefCard}
                options={options}
              />

              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                  marginTop: { xl: "5%" },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={false}
                    offset={0}
                    duration={500}
                  >
                    <Connect />
                  </Link>
                  <Learn />
                </Box>

                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ marginTop: { lg: "10px", xl: "35px" } }}
                >
                  <IconButton onClick={handlePrev}>
                    <img
                      src={PREV}
                      alt="nextButton"
                      style={{ width: "45px", height: "25px" }}
                    />
                  </IconButton>

                  <IconButton onClick={handleNext}>
                    <img
                      src={nextButton}
                      alt="nextButton"
                      style={{ width: "45px", height: "25px" }}
                    />
                  </IconButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            flexDirection: "column",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: { xs: "30px", md: "10px" },
            }}
          >
            <Link
              to="contact"
              spy={true}
              smooth={false}
              offset={0}
              duration={500}
            >
              <Connect />
            </Link>
            <Learn />
          </Box>

          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: { xs: "20px", md: "10px" },
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton onClick={handlePrev}>
              <img
                src={PREV}
                alt="nextButton"
                style={{ width: "45px", height: "25px" }}
              />
            </IconButton>

            <IconButton onClick={handleNext}>
              <img
                src={nextButton}
                alt="nextButton"
                style={{ width: "45px", height: "25px" }}
              />
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Element>
  );
};
export default Industries;
