import * as React from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { developmentItems } from "../../data";
import Connect from "../../components/buttons/Connect";
import Learn from "../../components/buttons/Learn";
import { Link } from "react-scroll";

const BasicAccordion = () => {
  const [expandedStates, setExpandedStates] = useState(
    developmentItems.map(() => false)
  );

  const handleAccordionToggle = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };
  return (
    <div>
      {developmentItems.map((item, index) => (
        <Accordion
          key={item.id}
          expanded={expandedStates[index]}
          onChange={() => handleAccordionToggle(index)}
          sx={{
            backgroundColor: expandedStates[index] ? "#300836" : "#300836",
            margin: 0,
            boxShadow: "none",
            "&.Mui-expanded": {
              margin: 0,
              boxShadow: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconButton style={{ color: "white" }}>
                {expandedStates[index] ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            }
            aria-controls={`panel${item.id}-content`}
            id={`panel${item.id}-header`}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={expandedStates[index] ? item.icon : item.icon}
                alt="webDevelopmentIcon"
                style={{ width: "30px", marginRight: "15px" }}
              />
              <Typography
                sx={{
                  color: expandedStates[index] ? "white" : "grey",
                  fontSize: "1.3rem",
                  fontFamily: "Gilroy-bold",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <img
              src={item.image}
              alt="webDevelopmentIcon"
              style={{
                width: "-webkit-fill-available",
                clipPath:
                  "polygon(6% 0, 100% 0, 100% 94%, 94% 100%, 0 100%, 0 6%)",
              }}
            />
            <Typography sx={{ color: "white" }}>
              {item.description}
            </Typography>
            <Box
              mt={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Link
                to="contact"
                spy={true}
                smooth={false}
                offset={0}
                duration={500}
              >
                <Connect />
              </Link>
              <Learn />
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default BasicAccordion;
