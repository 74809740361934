import React from "react";
import { Element } from "react-scroll";
import { Container } from "@mui/material";
import logo from "./../../assets/Logo.png";
import scrollDown from "./../../assets/scrollDown.svg";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";

import "./LandingPage.scss";

const LandingPage = () => {
  const initialColor = 'black'; // Set the initial color for this page
  const backgroundColor = useScrollBackgroundColor(initialColor);
  const scrollToNextSection = () => {
    const workSection = document.getElementById("work");
    if (workSection) {
      workSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Element id="home">
      <div className="hero-section" style={{ backgroundColor, transition: 'background-color 0.5s' }}>
        <Container className="center-box">
          <img src={logo} alt="" srcset="" />
          <div >
            <span>Building</span>
            <span>Success</span>
            <span>Together</span>
          </div>
        </Container>
        <div className="scroll-down" onClick={scrollToNextSection}>

          <div className="arrow bounce">
            <img src={scrollDown} alt="" srcSet="" width="30px" height="30px" />
          </div>
        </div>
      </div>
    </Element>
  );
};

export default LandingPage;
