import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ServiceCard from "../../components/serviceCard/serviceCard";
import { developmentItems } from "../../data";
import "./servcies.scss";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/system";
import { useRef, useState } from "react";
import { Link, Element } from "react-scroll";
import nextButton from "../../assets/nextbutton.png";
import prevButton from "../../assets/prevbutton.png";
import Connect from "../../components/buttons/Connect";
import Learn from "../../components/buttons/Learn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BasicAccordion from "./BasicAccordion";

const Services = () => {
  const initialColor = "#300836";
  const backgroundColor = useScrollBackgroundColor(initialColor);
  const [selectedItem, setSelectedItem] = useState(0);
  const [isItemHovered, setIsItemHovered] = useState(null);
  const containerRef = useRef(null);
  const carouselRef = useRef(null);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    setIsItemHovered(null);

    if (index === 5) {
      const container = containerRef.current;
      const nextItem = container.children[index + 1];

      if (nextItem) {
        const offset = nextItem.offsetTop - container.offsetTop;
        container.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  };

  const handleItemHover = (index) => {
    setIsItemHovered(index);
  };

  const handleItemLeave = () => {
    setIsItemHovered(null);
  };

  const handleNextClick = () => {
    carouselRef.current.next();

    const container = containerRef.current;
    const selectedItemIndex = selectedItem;
    const nextItem = container.children[selectedItemIndex + 1];

    if (nextItem) {
      const offset = nextItem.offsetTop - container.offsetTop;
      container.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const handlePrevClick = () => {
    carouselRef.current.prev();

    const container = containerRef.current;
    const selectedItemIndex = selectedItem;
    const prevItem = container.children[selectedItemIndex - 1];
    if (prevItem) {
      const offset = prevItem.offsetTop - container.offsetTop;
      container.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };


  const circleStyle = {
    padding: "2px",
    borderRadius: "50%",
    backgroundColor: "rgba(169, 169, 169, 0.3)",
    border: "2px solid #808080",
  };

  const titleStyle = {
    marginLeft: 16,
    fontFamily: "Gilroy-Bold",
    color: "#808080",
    cursor: "pointer",
    lineHeight: 1.8,
  };

  return (
    <Element name="services">
      <Box
        className="services__container"
        style={{ backgroundColor, transition: "background-color 0.5s" }}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Container
          sx={{
            paddingLeft: { lg: "10%", xl: "24px" },
            paddingRight: { lg: "10%", xl: "24px" },
            marginTop: { xl: "20px", md: "40px" },
          }}
        >
          <Typography
            sx={{
              marginLeft: { lg: "35px" },
              marginRight: { lg: "35px" },
              marginBottom: { lg: "30px" },
              fontSize: { xl: "3.3rem", lg: "37px", xs: "48px" },
            }}
            variant="h2"
          >
            INNOVATION MEETS{" "}
            <span className="headingGradient">EXPERTISE</span> WITH CUSTOM
            SOFTWARE SOLUTIONS TO{" "}
            <span className="headingGradient2">TRANSFORM YOUR BUSINESS.</span>
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                lg={6}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{ height: "420px", overflow: "scroll" }}
                  ref={containerRef}
                >
                  {developmentItems.map((item, index) => (
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: { xl: "18px", lg: "9px" },
                      }}
                      key={index}
                      onClick={() => handleItemClick(index)}
                    >
                      <Paper
                        sx={{
                          width: { xl: 40, lg: 35 },
                          height: { xl: 40, lg: 35 },
                        }}
                        elevation={3}
                        style={{
                          ...circleStyle,
                          background:
                            selectedItem === index || isItemHovered === index
                              ? `linear-gradient(90deg, rgba(0, 229, 255, 0.5) 20%, rgba(255, 0, 199, 0.5) 50%, rgba(255, 199, 0, 0.5) 80%)`
                              : circleStyle.backgroundColor,
                          border:
                            selectedItem === index || isItemHovered === index
                              ? "2px solid linear-gradient( rgba(0, 229, 255, 1) 20%, rgba(255, 0, 199, 1) 50%, rgba(255, 199, 0, 1) 80%)"
                              : circleStyle.border,
                        }}
                      >
                        <img
                          src={item.icon}
                          alt="Icon"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </Paper>
                      <Typography
                        // variant="h4"
                        className="work-text"
                        sx={{ fontSize: { xl: "1.7rem", lg: "25px" } }}
                        style={{
                          ...titleStyle,
                          color:
                            selectedItem === index || isItemHovered === index
                              ? "white"
                              : titleStyle.color,
                        }}
                        onMouseEnter={() => handleItemHover(index)}
                        onMouseLeave={handleItemLeave}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                  ))}
                </Box>
                <Box marginBottom="85px">
                  <Link
                    to="contact"
                    spy={true}
                    smooth={false}
                    offset={0}
                    duration={500}
                  >
                    <Connect />
                  </Link>
                  <Learn />
                </Box>
              </Grid>

              <Grid item xs={6} lg={6}>
                <ServiceCard
                  carouselRef={carouselRef}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              spacing={2}
              sx={{ marginTop: { lg: "-70px" } }}
            >
              <IconButton onClick={handlePrevClick}>
                <img
                  src={prevButton}
                  alt="nextButton"
                  style={{ width: "45px", height: "25px" }}
                />
              </IconButton>

              <IconButton onClick={handleNextClick}>
                <img
                  src={nextButton}
                  alt="nextButton"
                  style={{ width: "45px", height: "25px" }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Container>
      </Box>

      <Box
        className="services__container"
        style={{ backgroundColor, transition: "background-color 0.5s" }}
        sx={{ display: { sm: "flex", md: "none" } }}
      >
        <Container>
          <Typography
            sx={{
              fontSize: "48px",
            }}
            variant="h2"
          >
            WE DEVELOPED SOFTWARE{" "}
            <span className="headingGradient">SOLUTIONS THAT</span>{" "}
            <span className="headingGradient2">GROWS</span> STARTUPS AND
            BUSINESSES
          </Typography>
          <Box mt={3} ml={-1}>
            <BasicAccordion />
          </Box>
        </Container>
      </Box>
    </Element>
  );
};

export default Services;
