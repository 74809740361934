import Footer from "../components/footer/Footer";
import HamburgerMenu from "../components/hamburgerMenu/HamburgerMenu";
import Navbar from "../components/navbar/Navbar";
import "./layout.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <HamburgerMenu/>
      {children}
      <Footer />
    </>
  );
};

export default Layout;
