import React, { useRef } from "react";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Link, Element } from "react-scroll";
import nextButton from "../../assets/nextbutton.png";
import PREV from "../../assets/PREV.png";
import Connect from "../../components/buttons/Connect";
import Learn from "../../components/buttons/Learn";
import WorkCard from "../../components/workCard/workCard";
import "./work.scss";

const Work = () => {
  const initialColor = "#080D36"; // Set the initial color for this page
  const backgroundColor = useScrollBackgroundColor(initialColor);
  const carouselRef = useRef(null);

  const handleNextClick = () => {
    carouselRef.current.next();
  };

  const handlePrevClick = () => {
    carouselRef.current.prev();
  };

  return (
    <Element name="clients">
      <Box
        className="work"
        style={{ backgroundColor, transition: "background-color 0.5s" }}
      >
        <Box
          sx={{
            marginLeft: { lg: "210px", xl: "376px" },
            marginTop: { xl: "-33px" },
          }}
        >
          <Grid container spacing={{ xs: 20, md: 2 }}>
            <Grid item sm={12} lg={5} xl={4.7}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xl: "50px", lg: "37px", xs: "48px" },
                  marginTop: { xl: "95px", lg: "20px" },
                }}
              >
                <span className="headingGradient2">we empower</span>{" "}
                organizations to thrive and achieve their goals in the{" "}
                <span className="headingGradient">digital realm.</span>
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  fontSize: { xl: "19px", lg: "18px", xs: "22px" },
                  width: { xl: "420px", lg: "400px", xs: "320px" },
                  fontFamily: "Gilroy-Regular",
                  fontWeight: "400",
                  lineHeight: { xl: "32px" },
                  letterSpacing: "0em",
                  textAlign: "left",
                  height: "86px",

                  marginTop: { lg: "20px" },
                }}
              >
                Tired of cookie-cutter IT applications? We specialize in custom
                software solutions tailored to your unique vision, not some
                dusty old playbook. Our seasoned team of tech innovators and
                passionate problem solvers are fueled by one mission: to propel
                your business into the stratosphere with cutting-edge tech that
                disrupts and delights.
              </Typography>
            </Grid>

            <Grid item sm={12} lg={7} xl={7.3} sx={{ maxWidth: "100%" }}>
              <WorkCard carouselRef={carouselRef} />
            </Grid>
          </Grid>
          <Box
            className="connect-button"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", md: "flex-start" },
              }}
            >
              <Link
                to="contact"
                spy={true}
                smooth={false}
                offset={0}
                duration={500}
              >
                <Connect />
              </Link>
              <Learn />
            </Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                marginTop: { xs: "20px", md: "5px", xl: "3%", lg: "10px" },
                display: { xs: "flex" },
                justifyContent: { xs: "space-between", md: "flex-start" },
              }}
            >
              <IconButton onClick={handlePrevClick}>
                <img
                  src={PREV}
                  alt="nextButton"
                  style={{ width: "45px", height: "25px" }}
                />
              </IconButton>
              <IconButton onClick={handleNextClick}>
                <img
                  src={nextButton}
                  alt="nextButton"
                  style={{ width: "45px", height: "25px" }}
                />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Element>
  );
};

export default Work;
