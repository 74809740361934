import React from "react";
import emailjs from '@emailjs/browser';
import { Element } from "react-scroll";
import gradient from "./../../assets/gradient.png";
import "./contact.scss";
import { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Container,
} from "@mui/material";
import { useScrollBackgroundColor } from "../../components/scrollUtils/scrollUtils";
import Thankyou from "../../components/modal/Thankyou";

const Contact = () => {
  const initialColor = '#082e36'; // Set the initial color for this page
  const backgroundColor = useScrollBackgroundColor(initialColor);
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    fullName: Yup.string().required("Full Name is required"),
    projectDetails: Yup.string().required("Project Details are required"),
  });

  const sendEmail = async (templateParams) => {
    try {
      await emailjs.send(
        'service_meor58f',
        'template_u8jdbc8',
        templateParams,
        'MeIX5eb4igIQNziPe'
      );

      console.log('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Element name="contact" >
      <Box className="contact-section" style={{ backgroundColor, transition: 'background-color 0.5s' }}>
        <Container sx={{ paddingLeft: { lg: "10%", xl: "24px" }, paddingRight: { lg: "10%", xl: "24px" } }}>
          <Grid container xs={12} sm={12} md={12} >
            <Grid item xs={12} sm={12} md={12} lg={8} >
              <Typography
                variant="h2"

                mb={5}
              >
                READY TO
                <span className="headingGradient"> GET STARTED</span>?
              </Typography>

              <Formik
                initialValues={{
                  email: "",
                  fullName: "",
                  projectDetails: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  console.log(values);
                  const templateParams = {
                    to_email: values.email,
                    from_name: values.fullName,
                    project_details: values.projectDetails,
                  };
                  sendEmail(templateParams);
                  setOpen(true);
                  resetForm();
                }}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div style={{
                      display: "flex",
                      marginTop: "35px",
                      marginBottom: "2px"
                    }}>
                      <Field
                        as={TextField}
                        type="email"
                        id="email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        className="textField"

                        InputLabelProps={{ style: { color: "white", letterSpacing: "0.1em", fontFamily: "Gilroy-Regular", fontWeight: "500", fontSize: "18px" } }}
                      />
                      {errors.name && <div id="feedback">{errors.name}</div>}

                      <Field
                        as={TextField}
                        type="text"
                        id="fullName"
                        name="fullName"
                        variant="outlined"
                        fullWidth
                        label="Full Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                        className="textField"
                        InputLabelProps={{ style: { color: "white", letterSpacing: "0.1em", fontFamily: "Gilroy-Regular", fontWeight: "500", fontSize: "18px" } }}
                        style={{ marginLeft: "10px" }}
                      />
                      {errors.name && <div id="feedback">{errors.name}</div>}
                    </div>

                    <Field
                      as={TextField}
                      type="text"
                      id="projectDetails"
                      name="projectDetails"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      label="Project Details"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.projectDetails}
                      className="textField"
                      InputLabelProps={{ style: { color: "white", letterSpacing: "0.1em", fontFamily: "Gilroy-Regular", fontWeight: "500", fontSize: "18px" } }}
                    />
                    {errors.name && <div id="feedback">{errors.name}</div>}

                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      className="submitButton"
                      sx={{
                        marginTop: "6px"
                      }}
                      style={{ textTransform: "none" }}
                    >
                      Submit
                    </Button>
                  </form>
                )}
              </Formik>

              <Thankyou open={open} setOpen={setOpen} />
            </Grid>
            <Grid
              item
              lg={4}
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                justifyContent: { lg: "end" }
              }}
            >
              <img
                src={gradient}
                alt="Example"
                className="gradient-image"
              // style={{ width: "300px", height: "340px" }}

              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Element>
  );
};

export default Contact;
