import Button from "@mui/material/Button";

import "./connect.scss"

const Connect = () => {

  return (
    <Button className="connect" >
      LET'S CONNECT
    </Button>
  );
};

export default Connect;
