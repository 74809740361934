import React, { useState, useRef, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { developmentItems } from "../../data";

const ServiceCard = ({ carouselRef, selectedItem, setSelectedItem }) => {
  const options = {
    items: 1,
    dots: false,
    mouseDrag: false,
    startPosition: selectedItem,
    onChanged: (event) => setSelectedItem(event.item.index),
  };

  return (
    <>
      <OwlCarousel {...options} ref={carouselRef}>
        {developmentItems.map((product, index) => (
          <div
            key={index}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Card
              sx={{
                height: { xl: "36rem", lg: "29rem" },
                clipPath:
                  "polygon(6% 0, 100% 0, 100% 94%, 94% 100%, 0 100%, 0 6%)",
                backgroundColor: "transparent",
              }}
              key={index}
            >
              <CardMedia
                component="img"
                image={product.image}
                alt="product name"
                sx={{
                  height: { xl: "26rem", lg: "19rem" },
                  clipPath:
                    "polygon(6% 0, 100% 0, 100% 94%, 95% 100%, 0 100%, 0 6%)",
                }}
              />

              <Typography
                variant="body2"
                component="div"
                sx={{
                  marginTop: "8px",
                  fontFamily: "Gilroy-Regular",
                  fontWeight: 400,
                  // marginBottom: 4,
                  fontSize: { xl: "19px", lg: "18px" },
                  width: { xl: "550px", lg: "400px" },
                  color: "white",
                }}
              >
                {product.description}
              </Typography>
            </Card>
          </div>
        ))}
      </OwlCarousel>
    </>
  );
};

export default ServiceCard;
