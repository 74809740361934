import React from "react";
import LandingPage from "./pages/LandingPage";
import Services from "./pages/Services";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
import Layout from "./Layout/Layout";
import Industries from "./pages/Industries";
import "./App.css";

function App() {
  return (
    <Layout>
      <LandingPage />
      <Work />
      <Services />
      <Industries />
      <Contact />
    </Layout>
  );
}

export default App;
