import React, { useState, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { cardData } from "../../data";
import { Avatar, Box, Grid } from "@mui/material";
import WorkCardLogo from "./workCardLogo";

const WorkCard = ({ carouselRef }) => {
  const options = {
    // items: 2.7,
    loop: true,
    mouseDrag: false,
    dots: false,
    margin: 42,
    autoWidth: true,
    responsive: {
      0: {
        items: 1.04,
      },
      600: {
        items: 2.2,
      },
      900: {
        items: 3.1,
      },
      1200: {
        items: 2.1,
      },
      1536: {
        items: 2.3,
      },
    },
  };

  return (
    <>
      <OwlCarousel loop {...options} ref={carouselRef}>
        {cardData.map((product, index) => (
          <Grid item key={index}>
            <div key={index}>
              <Card
                sx={{
                  width: { xl: "22.2rem", lg: "18rem", xs: "20rem" },
                  height: { xl: "530px", lg: "430px", xs: "560px" },
                  marginTop: { xs: "120px", xl: "105px", lg: "30px" },
                  clipPath:
                    "polygon(5% 0, 100% 0, 100% 95%, 95% 100%, 0 100%, 0 5%)",
                }}
                key={index}
              >
                <WorkCardLogo
                  background={product.background}
                  logo={product.logo}
                />
                <CardMedia
                  component="img"
                  image={product.image}
                  alt="product name"
                  sx={{ height: { xl: "20rem", lg: "16rem", xs: "23rem" } }}
                />

                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "black",
                      fontFamily: "Gilroy-Bold",
                      fontWeight: "800",
                      marginBottom: 1,
                      fontSize: { xl: "28px", lg: "20px" },
                    }}
                  >
                    {product.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      fontFamily: "Gilroy-Regular",
                      fontWeight: "800",
                      fontSize: { xl: "16px", lg: "13px" },
                      color: "black",
                      marginBottom: 1,
                      fontStyle: "normal",
                    }}
                  >
                    {product.description}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ))}
      </OwlCarousel>
    </>
  );
};

export default WorkCard;
