import React from "react";
import OwlCarousel from "react-owl-carousel";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { headingsAndBullets } from "../../data";

const IndustriesCard = ({ carouselRef, options }) => {

  return (
    <>
      <Grid container>
        <OwlCarousel {...options} ref={carouselRef}>
          {headingsAndBullets?.map((product, i) => (
            <Grid
              item
              key={i}
              sx={{ display: "flex", justifyContent: "left" }}
            >
              <CardMedia
                component="img"
                image={product.image}
                alt="product name"
                sx={{
                  border: "2px solid transparent",
                  width: "100% !important",
                  height: { xl: "670px", lg: "430px", xs: "280px" },
                  // {md:"fit-content", lg:"fit-content"},
                  clipPath:
                    "polygon(6% 0, 100% 0, 100% 94%, 94% 100%, 0 100%, 0 6%)",
                }}
              />
            </Grid>
          ))}
        </OwlCarousel>
      </Grid>
    </>
  );
};

export default IndustriesCard;
