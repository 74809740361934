import { Button } from "@mui/material";

const Learn = () => {
  return (
    <Button
      variant="outlined"
      sx={{
        fontSize: "18px",
        fontWeight: "600",
        height: "50px",
        width: "170px",
        borderRadius: "8px",
        color: "white",
        marginLeft: "10px",
        borderColor: "white",
        fontFamily: "Gilroy-Bold",
        letterSpacing: "0.17rem",
        "&:hover": {
          color: "black" ,
          background: "white",
          border: "2px solid white",
          fontSize: "18px",
          fontWeight: "600",
        },
      }}
    >
      LEARN MORE
    </Button>
  );
};

export default Learn;
