import React from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { navlinks } from "../../data";
import { Link } from "react-scroll";
import "./navbar.scss";
import { Box, Container } from "@mui/material";
import Connect from "../buttons/Connect";

const Navbar = () => {
  return (
    <>
      <Container sx={{ paddingLeft: { lg: "10%", xl: "24px" } }}>
        <Box className="outer-navbar__container navlinks__container blur-background">
          <Logo />

          {navlinks.map((link, i) => (
            <Link
              key={i}
              activeClass="active1"
              to={link.to}
              spy={true}
              smooth={true}
              offset={link.offset}
              duration={500}
              className="navlink"
            >
              {link.title}
            </Link>
          ))}
          <div className="button-container">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <Connect />
            </Link>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Navbar;
