import { Avatar, Box } from "@mui/material";
import React from "react";

const WorkCardLogo = ({ background, logo }) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          bottom: {xl:"35%", lg:"48%", xs:"42%"},
          right: {xl: "10%", lg: "18%", xs:"20%"},
          width: {xl:"4.2rem", },
          height: {xl:"4.2rem",},
          zIndex: 1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 1)",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            position: "absolute",
            width: "4.2rem",
            height: "4.2rem",
            background: background,
            padding: "5px",
          }}
        >
          <img src={logo} alt="Logo" />
        </Avatar>
      </Box>
    </>
  );
};

export default WorkCardLogo;
