import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import "./thankyou.scss";

const Thankyou = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth className="dialog__container">
        <DialogContent className="dialog-content__container">
          <h2>THANK YOU</h2>
          <h5>We will get back to you shortly.</h5>
          <h5>Yours Truly.</h5>
        </DialogContent>

        <DialogActions className="dialog-actions">
          <Button variant="contained" className="btn" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Thankyou;
