import android from "../assets/Android OS.png";
import azure from "../assets/Azure.png";
import css from "../assets/CSS3.png";
import flutter from "../assets/Flutter.png";
import web from "../assets/Group 92.png";
import java from "../assets/Java.png";
import mySQL from "../assets/MySQL Logo.png";
import react from "../assets/React Native.png";
import Rectangle from "../assets/Rectangle.png";
import swift from "../assets/Swift.png";
import wordpress from "../assets/WordPress.png";
import aws from "../assets/aws.png";
import cplus from "../assets/cplus.png";
import { ReactComponent as Devops } from "../assets/devops.svg";
import html from "../assets/html.png";
import hybronCardImage from "../assets/hybron.png";
import js from "../assets/js.png";
import mobileIcon from "../assets/mobileIcon.png";
import python from "../assets/python.png";
import rails from "../assets/rails.png";
import ruby from "../assets/ruby.png";
import serviceCardImage from "../assets/serviceCardImage.jpeg";
import ts from "../assets/ts.png";
import viewJS from "../assets/viewJS.svg";
import webIcon from "../assets/webDevelopmentIcon.png";
import z2 from "../assets/z2.png";
import z3 from "../assets/z3.png";
import z4 from "../assets/z4.jpg";

// <---- Client section ---->
// Client Logo
import veraCircle from "../assets/Clients_Carousel/Client_Logo_Veralegal.png";
import hybronCircle from "../assets/Clients_Carousel/Client_Logo_Byron.png";
import neerobCircle from "../assets/Clients_Carousel/Client_Logo_Neerob.png";
import nsrLogo from "../assets/Clients_Carousel/Client_Logo_NSR.png";
import humanytekLogo from "../assets/Clients_Carousel/Client_Logo_Humanytek.png";
import planspaceLogo from "../assets/Clients_Carousel/Client_Logo_Planspace.io.png";
import rahgeerLogo from "../assets/Clients_Carousel/Client_Logo_Rahgeer.pk.png";
import secureChatLogo from "../assets/securechatlogo.png";
import swagkashLogo from "../assets/Clients_Carousel/Client_Logo_Swagcash.png";
import wellaviLogo from "../assets/Clients_Carousel/Client_Logo_Well Avi.png";
// CLient Main Images
import veraCardImage from "../assets/Clients_Carousel/Client_VeraLegal.png";
import neerobCardImage from "../assets/Clients_Carousel/Client_Neerob.png";
import humanytek from "../assets/Clients_Carousel/Client_Humanytek.png";
import planspace from "../assets/Clients_Carousel/Client_PlanSpace.png";
import rahgeer from "../assets/Clients_Carousel/Client_Rahgeer.pk.png";
import swagkash from "../assets/Clients_Carousel/Client_Swagcash.png";
import wellavi from "../assets/Clients_Carousel/Client_Well Avi.png";
import securechat from "../assets/Clients_Carousel/Client_Secure Chat.png";
import nsr from "../assets/Clients_Carousel/Client_NSR.png";

// <--- Services Section --->

// <--- Services Card --->
import sassIcon from '../assets/Services_icon/SaaS Applications Hover-clicked.png'
import MobileIcon from '../assets/Services_icon/Icn_Hover-clicked_Mobile Solutions Hove-clicked.png'
import AiIcon from '../assets/Services_icon/ML.png'
import EnterpriseIcon from '../assets/Services_icon/Icn_Hover-clicked_Enterprise Software Solutions.png'
import SoftwareIcon from '../assets/Services_icon/Icn_Hover-clicked_Software Consulting.png'
import QualityIcon from '../assets/Services_icon/Icn_Hover-clicked_Quality Assurance & Automated Testing.png'
import UserIcon from '../assets/Services_icon/Icn_Hover-clicked_User Experience & Design.png'
import CloudIcon from '../assets/Services_icon/Icn_Hover-clicked_Cloud Computing Services.png'
import DevOpsIcon from '../assets/Services_icon/Icn_Hover-clicked_DevOps Services.png'
import MaintenanceIcon from '../assets/Services_icon/Icn_Hover-clicked_Maintenance and Support.png'
import BlockIcon from '../assets/Services_icon/Icn_Hover-clicked_Blockchain Development_.png'
import IotIcon from '../assets/Services_icon/Icn_Hover-clicked_Icn_Hover-clicked_IoT (Internet of Things) Development.png'

// <--- Services Card --->
import sass from '../assets/Service_Carousel/Service_SaaS Applications_.png'
import mobile from '../assets/Service_Carousel/Service_Mobile Solutions.png'
import ai from '../assets/Service_Carousel/ML.png'
import enterprise from '../assets/Service_Carousel/Service_Enterprise Software Solutions.png'
import consulting from '../assets/Service_Carousel/Service_Software Consulting.png'
import quality from '../assets/Service_Carousel/Service_Quality Assurance & Automated Testing.png'
import userExperience from '../assets/Service_Carousel/Service_User Experience & Design.png'
import cloud from '../assets/Service_Carousel/Service_Cloud Computing Services.png'
import devops from '../assets/Service_Carousel/Service_DevOps Services.png'
import maintenance from '../assets/Service_Carousel/Service_Maintenance and Support.png'
import blockchain from '../assets/Service_Carousel/Service_Blockchain Development_.png'
import iot from '../assets/Service_Carousel/Service_IoT (Internet of Things) Development.png'

// <--- Industries --->
import Legal from '../assets/Industries_Carousel/Image_Industry_LEGAL.png'
import realEstate from '../assets/Industries_Carousel/Image_Industry_REAL ESTATE.png'
import ecommerce from '../assets/Industries_Carousel/Image_Industry_E-COMMERCE.png'
import health from '../assets/Industries_Carousel/Image_Industry_HEALTHCARE.png'
import fintech from '../assets/Industries_Carousel/Image_Industry_FINTECH.png'
import insurance from '../assets/Industries_Carousel/Image_Industry_INSURANCE.png'
import travel from '../assets/Industries_Carousel/Image_Industry_TRAVEL.png'
import professional from '../assets/Industries_Carousel/Image_Industry_PROFESSIONAL SERVICES.png'
import dinning from '../assets/Industries_Carousel/Image_Industry_DINING.png'
import transportation from '../assets/Industries_Carousel/Image_Industry_TRANSPORTATION.png'
import enterprises from '../assets/Industries_Carousel/Image_Industry_ENTERPRISES.png'
import education from '../assets/Industries_Carousel/Image_Industry_EDUCATION.png'


const navlinks = [
  {
    to: "home",
    offset: 0,
    title: "HOME",
  },

  {
    to: "clients",
    offset: 0,
    title: "CLIENTS",
  },

  {
    to: "services",
    offset: 0,
    title: "SERVICES",
  },
  {
    to: "industries",
    offset: 0,
    title: "INDUSTRIES",
  },
];

const service = [
  {
    id: 1,
    component: <Devops />,
    title: "Web Deployment",
    logo: web,
    expandedLogo: web,
    expandedImage: serviceCardImage,
    expandedDescription:
      "We developed software solutions that grows startups and businesses. We developed software solutions that grows startups and businesses",
  },
  {
    id: 2,
    component: <Devops />,
    title: "Mobile Deployment",
    logo: web,
    expandedLogo: web,
    expandedImage: serviceCardImage,
    expandedDescription:
      "We developed software solutions that grows startups and businesses. We developed software solutions that grows startups and businesses",
  },
  {
    id: 3,
    component: <Devops />,
    title: "AI & Machine Learning",
    logo: web,
    expandedLogo: web,
    expandedImage: serviceCardImage,
    expandedDescription:
      "We developed software solutions that grows startups and businesses. We developed software solutions that grows startups and businesses",
  },
  {
    id: 4,
    component: <Devops />,
    title: "Devops",
    logo: web,
    expandedLogo: web,
    expandedImage: serviceCardImage,
    expandedDescription:
      "We developed software solutions that grows startups and businesses. We developed software solutions that grows startups and businesses",
  },
  {
    id: 5,
    component: <Devops />,
    title: "Backend ",
    logo: web,
    expandedLogo: web,
    expandedImage: serviceCardImage,
    expandedDescription:
      "We developed software solutions that grows startups and businesses. We developed software solutions that grows startups and businesses",
  },
  {
    id: 6,
    component: <Devops />,
    title: "User Experience Design",
    logo: web,
    expandedLogo: web,
    expandedImage: serviceCardImage,
    expandedDescription:
      "We developed software solutions that grows startups and businesses. We developed software solutions that grows startups and businesses",
  },
];

const cardData = [
  {
    name: "Vera Legal",
    description:
      "Legal services for everyone. A platform to connect with expert lawyers specialized in your case for free.\n",
    categories: ["Legal", "Lawyer"],
    image: veraCardImage,
    logo: veraCircle,
    background: "white",
  },
  {
    name: "Neerob",
    description:
      "Restaurant of desi cuisines, offer cuisines from everyday staples such as rice, fish, we've got you covered.\n\n",
    categories: ["Restaurant", "Cuisines"],
    image: neerobCardImage,
    logo: neerobCircle,
    background: "#2F75E6",
  },
  {
    name: "Byron",
    description:
      "Automate your busy work with people-powered assistants. Delegate tasks to a talented, diverse team of experienced US-based executive assistants.",
    categories: ["Automation", "Assistants"],
    image: hybronCardImage,
    logo: hybronCircle,
    background: "#456F90",
  },
  {
    name: "Humanytek",
    description:
      "We help companies build, capitalize and sustain themselves in the future, with innovative business transformation consultancy services.",
    categories: ["Productivity", "Solutions"],
    image: humanytek,
    logo: humanytekLogo,
    background: "#456F90",
  },
  {
    name: "PlanSpace",
    description:
      "Connect with seasoned planners dedicated to bringing your vision to life, all on a platform that ensures your events are truly unforgettable.",
    categories: ["Events", "Management"],
    image: planspace,
    logo: planspaceLogo,
    background: "#456F90",
  },
  {
    name: "Rahgeer",
    description:
      "Plan your trips with a one-stop, travel solutions platform. Enjoy an amazing experience with customized deals, and a stress-free journey. ",
    categories: ["Advisors", "Travel"],
    image: rahgeer,
    logo: rahgeerLogo,
    background: "#456F90",
  },
  {
    name: "NSR",
    description:
      "Efficient tax and accounting solutions tailored to your needs. Connect with seasoned professionals to navigate complex financial matters with ease.",
    categories: ["Accounting", "Banking"],
    image: nsr,
    logo: nsrLogo,
    background: "#456F90",
  },
  {
    name: "Secure Chat",
    description:
      "Secure messaging for all. Connect with trusted professionals for confidential conversations on our platform, ensuring your privacy and peace of mind.",
    categories: ["Connectivity", "Socializing"],
    image: securechat,
    logo: secureChatLogo,
    background: "#456F90",
  },
  {
    name: "Swagkash",
    description:
      "Unlock lucrative opportunities with our gaming platform. Connect with fellow players and earn real cash rewards while enjoying your favorite games.",
    categories: ["Rewards", "Games"],
    image: swagkash,
    logo: swagkashLogo,
    background: "#456F90",
  },
  {
    name: "Well Avi",
    description:
      "Empowering your well-being journey with seasoned fitness professionals tailored to your goals towards a healthier and happier you",
    categories: ["Fitness", "Health"],
    image: wellavi,
    logo: wellaviLogo,
    background: "#456F90",
  },
];

const developmentItems = [
  {
    count: 1,
    icon: sassIcon,
    title: "SaaS Applications",
    image: sass,
    description:
      "Elevate your online presence with our cutting-edge SaaS applications. From dynamic aggregator platforms to e-commerce solutions and content management systems (CMS), we bring your vision to life for your users.",
  },
  {
    count: 2,
    icon: MobileIcon,
    title: "Mobile Solutions",
    image: mobile,
    description:
      "Make a lasting impact in the mobile space with our captivating applications. Our expertise spans iOS, Android, and cross-platform app and games development, combining functionality with creativity.",
  },
  {
    count: 3,
    icon: AiIcon,
    title: "AI / ML",
    image: ai,
    description:
      "Our expertise spans creation of advanced linguistic algorithms, seamless API integration, and cutting-edge generative AI solutions. We specialize in Natural Language Processing, Large Language Models, AI API integration, GenAI, and Voice, Video, and Image AI development.",
  },
  {
    count: 4,
    icon: EnterpriseIcon,
    title: "Enterprise Software Solutions",
    image: enterprise,
    description:
      "Streamline your operations with our tailored enterprise solutions. From Enterprise Resource Planning (ERP) to Customer Relationship Management (CRM), we empower large-scale organizations for unparalleled efficiency.",
  },
  {
    count: 5,
    icon: SoftwareIcon,
    title: "Software Consulting",
    image: consulting,
    description:
      "Navigate the tech landscape confidently with our expert consulting services. Gain insights into software architecture, technology stacks, and strategic project planning for informed decision-making.",
  },
  {
    count: 6,
    icon: QualityIcon,
    title: "Quality Assurance & Automated Testing",
    image: quality,
    description:
      "Your software's reliability is our priority. Benefit from our meticulous automated testing services, ensuring your applications are bug-free, perform optimally, and meet the highest quality standards.",
  },
  {
    count: 7,
    icon: UserIcon,
    title: "User Experience & Design",
    image: userExperience,
    description:
      "Immerse your users in captivating interfaces designed for optimal user experiences. Our design team creates visually appealing and user-friendly interfaces that leave a lasting impression.",
  },
  {
    count: 8,
    icon: CloudIcon,
    title: "Cloud Computing Services",
    image: cloud,
    description:
      "Unlock the power of the cloud with our scalable and flexible solutions. From cloud migration to the development of cloud-native applications, we ensure your software is optimized for the digital era.",
  },
  {
    count: 9,
    icon: DevOpsIcon,
    title: "DevOps Services",
    image: devops,
    description:
      "Boost collaboration and productivity with our DevOps expertise. Seamlessly integrate development and operations, and streamline your software development lifecycle with continuous integration, continuous delivery (CI/CD), and automation.",
  },
  {
    count: 10,
    icon: MaintenanceIcon,
    title: "Maintenance and Support",
    image: maintenance,
    description:
      "Trust Techfy for dedicated support, regular updates, and meticulous maintenance of your software. We ensure the smooth operation of your applications with timely issue resolution and updates.",
  },
  {
    count: 11,
    icon: BlockIcon,
    title: "Blockchain Development",
    image: blockchain,
    description:
      "Revolutionize transactions with our blockchain-powered solutions. Explore secure and transparent transactions, identity verification, and supply chain management tailored for your business needs.",
  },
  {
    count: 12,
    icon: IotIcon,
    title: "IoT (Internet of Things) Development",
    image: iot,
    description:
      "Connect your applications with IoT devices to unlock new possibilities. Techfy specializes in smart homes, industrial IoT, and innovative IoT applications that redefine connectivity.",
  },
];

const headingsAndBullets = [
  {
    heading: (
      <h2>
        STREAMLINED<span className="headingGradient2"> LEGAL WORKFLOWS </span>{" "}
        WITH AI-POWERED SOFTWARE TO TAILOR SOLUTIONS FOR
        <span className="headingGradient">{""} SMARTER, FAIRER OUTCOMES</span>
      </h2>
    ),
    bullets: [
      "Case management evolution for legal professionals",
      "Streamlining legal document workflows",
      "Next-gen contract management solutions",
      "Cutting-edge compliance and regulatory tools",
      "Efficient electronic billing and time tracking",
      "Elevated client communication experiences",
      "Workflow automation revolution",
      "Robust data security and confidentiality measures",
    ],
    image: Legal,
  },
  {
    heading: (
      <h2>
        TRANSFORMING{" "}
        <span className="headingGradient2">{" "}REAL ESTATE DYNAMICS</span> WITH
        <span className="headingGradient">{" "} INNOVATIVE SOLUTIONS.</span>
      </h2>
    ),
    bullets: [
      "Property management reimagined through innovative UX",
      "Revolutionizing real estate CRM experiences",
      "Futuristic online property listing platforms",
      "Immersive virtual property tours and visualization",
      "Lease management and document automation excellence",
      "Seamless integrated payment and invoicing",
      "Data analytics shaping market insights",
      "Mobile apps for elevated property interactions",
    ],
    image: realEstate,
  },
  {
    heading: (
      <h2>
        IGNITING <span className="headingGradient2">E-COMMERCE GROWTH&nbsp;</span>WITH
        <span className="headingGradient">{" "} DYNAMIC SOFTWARE SOLUTIONS, </span>ONE
        CLICK AT A TIME.
      </h2>
    ),
    bullets: [
      "Crafting unique e-commerce web experiences",
      "Seamlessly integrated payment gateways",
      "Shopping cart optimization for higher conversions",
      "Smart inventory management solutions",
      "Personalized recommendation engines",
      "Effortless order tracking and fulfillment",
      "Mobile commerce apps shaping user journeys",
      "Analytics driving e-commerce success",
    ],
    image: ecommerce,
  },
  {
    heading: (
      <h2>
        CUSTOM <span className="headingGradient2">FINTECH SOLUTIONS</span> TO
        EMPOWER SEAMLESS
        <span className="headingGradient">{" "}FINANCIAL TRANSACTIONS</span>
      </h2>
    ),
    bullets: [
      "Core banking redefined through cutting-edge tech",
      "Seamless online and mobile banking experiencess",
      "Fraud detection and prevention algorithms",
      "Risk management for the digital age",
      "Regulatory compliance at the forefront",
      "Customer relationship excellence in banking",
      "Real-time transaction monitoring innovation",
      "AI driven financial insights",
    ],
    image: fintech,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">REINVENTING INSURANCE</span>{" "}
        OPERATIONS THROUGH
        <span className="headingGradient">{" "}STRATEGIC INNOVATION</span>
      </h2>
    ),
    bullets: [
      "Insurance policies managed with tech precision",
      "Claims processing automation for efficiency",
      "Innovative underwriting software development",
      "Immersive virtual property tours and visualization",
      "Tech-driven risk assessment and analytics",
      "Customer-centric self-service portals",
      "Advanced fraud detection algorithms",
      "Compliance and regulatory reporting",
      "Insurance CRM shaping client relationships",
    ],
    image: insurance,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">ADVANCING HEALTHCARE</span> DELIVERY
        THROUGH
        <span className="headingGradient"> {" "}INNOVATIVE TECH</span>
      </h2>
    ),
    bullets: [
      "Electronic health record management for the digital era",
      "Virtual care platforms for telemedicine delivery",
      "Patient-centric appointment scheduling tech",
      "Medical billing and coding efficiency",
      "Analytics driving healthcare insights",
      "Remote patient monitoring excellence",
      "Custom healthcare apps for personalized care",
    ],
    image: health,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">
          EMPOWERING PROFESSIONAL SERVICES
        </span>{" "}
        WITH CUSTOM{" "}
        <span className="headingGradient">{" "}SOLUTIONS THAT CHANGE LIVES</span>
      </h2>
    ),
    bullets: [
      "Case management excellence for social services",
      "Tech-powered client intake and assessment",
      "Resource allocation and tracking innovations",
      "Program evaluation and impact assessment tools",
      "Reporting and compliance tech",
      "Mobile applications for efficient fieldwork",
      "Automated eligibility determination solutions",
      "Collaboration platforms enhancing inter-agency communication",
    ],
    image: professional,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">ELEVATING DINING</span> EXPERIENCES
        FOR THE
        <span className="headingGradient">{" "}DIGITAL AGE</span>
      </h2>
    ),
    bullets: [
      "Online reservation and table management",
      "Point of Sale (POS) excellence for restaurants",
      "Menu customization and digital ordering innovations",
      "Efficient inventory and supply chain tech",
      "Customer loyalty programs with a tech edge",
      "Kitchen management and order tracking efficiency",
      "Staff scheduling and payroll solutions",
      "Tech-driven customer feedback and review management",
    ],
    image: dinning,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">REDEFINING TRAVEL</span> EXPERIENCES,
        CRAFTING
        <span className="headingGradient">{" "}IMMERSIVE JOURNEYS</span>
      </h2>
    ),
    bullets: [
      "Online booking platforms shaping travel",
      "Innovative travel itinerary planning tech",
      "Mobile travel guides for personalized journeys",
      "Customer loyalty programs for travel enthusiasts",
      "Destination management with a tech edge",
      "Tour operator CRM solutions driving efficiency",
      "Real-time travel alerts and notifications",
      "Seamless integration with third-party travel services",
    ],
    image: travel,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">EMPOWERING ENTERPRISES</span> WITH
        COMPREHENSIVE
        <span className="headingGradient">{" "}SOFTWARE SOLUTIONS</span>
      </h2>
    ),
    bullets: [
      "Enterprise resource planning (ERP) systems excellence",
      "Customer relationship mastery in the digital age",
      "Mobile travel guides for personalized journeys",
      "Business intelligence and analytics driving insights",
      "Collaboration and communication tech tools",
      "Workflow automation for operational efficiency",
      "Employee training and development innovations",
      "Cybersecurity measures for data protection",
      "Scalable and flexible cloud solutions",
    ],
    image: enterprises,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">TRANSFORMING EDUCATION {" "}</span> THROUGH
        <span className="headingGradient">{" "}INNOVATIVE EDTECH SOLUTIONS</span>
      </h2>
    ),
    bullets: [
      "Learning management system (LMS) evolution",
      "Online course creation and delivery platforms",
      "Student information system (SIS) innovations",
      "E-learning content authoring for educators",
      "Virtual classrooms and video conferencing tech",
      "Assessment and grading automation solutions",
      "Parent-teacher communication portals",
      "Analytics driving student performance insights",
    ],
    image: education,
  },
  {
    heading: (
      <h2>
        <span className="headingGradient2">REVOLUTIONIZING TRANSPORTATION</span>{" "}
        THROUGH
        <span className="headingGradient">{" "}TECH INNOVATIONS</span>
      </h2>
    ),
    bullets: [
      "Fleet management and tracking with tech precision",
      "Route optimization and logistics tech solutions",
      "Vehicle maintenance and repair innovations",
      "Real-time tracking for passengers and cargo",
      "Transportation network and infrastructure planning",
      "Automated ticketing and fare collection tech",
      "Driver performance monitoring and safety tools",
      "ntegration with emerging transportation technologies",
    ],
    image: transportation,
  },
];

export {
  cardData,
  developmentItems,
  navlinks,
  service,
  headingsAndBullets,
};
